import styled from '@emotion/styled';

export const ImageBorderGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, min(100%, 226px));
    gap: 64px;
    justify-content: center;
    margin-top: 32px;

    @media (min-width: 1024px) {
        margin-top: 64px;
    }
`;
