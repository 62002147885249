import { Article } from '@/Article';
import { CommonHero } from '@/CommonHero';
import { ImageBorder } from '@/ImageBorder';
import { ImageBorderGrid } from '@/ImageBorderGrid';
import { graphql, useStaticQuery } from 'gatsby';
import {
    GatsbyImage,
    IGatsbyImageData,
    ImageDataLike,
    StaticImage,
    getImage,
} from 'gatsby-plugin-image';
import { Fragment } from 'react';

const Yeliana = () => {
    type DataType = {
        allFile: {
            nodes: ImageDataLike[];
        };
    };

    const data: DataType = useStaticQuery(graphql`
        query yelianaPageImages {
            allFile(
                filter: { relativeDirectory: { eq: "about-us/yeliana" } }
                sort: { order: ASC, fields: name }
                limit: 2
            ) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(
                            width: 216
                            height: 317
                            transformOptions: { cropFocus: CENTER }
                        )
                    }
                }
            }
        }
    `);

    const images = data.allFile.nodes.map(node => getImage(node) as IGatsbyImageData);

    return (
        <Fragment>
            <CommonHero heading="Yeliana Nikolskaya - Skincare Specialist at Beauty Religion">
                <StaticImage
                    src="../../../assets/images/about-us/yeliana/hero.jpg"
                    alt=" yeliana nikolskaya"
                    width={846}
                    height={430}
                    loading="eager"
                />
            </CommonHero>
            <Article>
                <p>
                    Yeliana Nikolskaya, Nurse Practitioner, RN, MSN exclusively specializes in
                    facial rejuvenation treatments through dermal fillers and Botox. Yeliana
                    combines her artistic talent, vision of the anatomy of beauty,{' '}
                    {new Date().getFullYear() - 2000} years of cosmetic dermatology experience, and
                    gentle and caring touch to erase years off your face and recreate a
                    younger-looking &quot;you&quot;!
                </p>
                <ImageBorderGrid>
                    {images.map((img, i) => (
                        <ImageBorder key={i}>
                            <GatsbyImage image={img} alt=" yeliana nikolskaya" />
                        </ImageBorder>
                    ))}
                </ImageBorderGrid>
            </Article>
        </Fragment>
    );
};

export default Yeliana;
